/** @format */

.main_container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100vh;
}

.redirection_image {
	height: 200px;
	width: 250px;
	margin-bottom: 20px;
}

.main_box {
	text-align: center;
}

.text-muted {
	margin: 0;
}

.redirection_button {
	margin-top: 20px;
}

.messageContainer {
	padding: 10px 10px 20px 10px;
	height: 80dvh;
	overflow: scroll;
	overflow-x: hidden;
	display: flex;
	flex-direction: column;
}

.sender-message {
	width: fit-content;
	padding: 10px;
	margin: 8px;
	background-color: #806bfe;
	color: white;
	align-self: flex-end;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
	display: flex;
	flex-direction: column;
}

.receiver-message {
	width: fit-content;
	padding: 10px;
	margin: 8px;
	background-color: whitesmoke;
	color: black;
	align-self: flex-start;
	border-top-left-radius: 10px;
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
	display: flex;
	flex-direction: column;
}

// .message{
//   display: flex;
//   align-items: center;
// }

.message-time {
	font-size: 0.6rem;
	color: black;
	margin-top: 4px;
	align-self: flex-end;
}

.date-header {
	display: flex;
	justify-content: center;
}

.custom_file_upload input[type="file"] {
	display: none !important;
}

.image_upload {
	position: absolute;
	right: 5px;
	top: 6px;
}

.css-ofrcno-MuiGrid-root > .MuiGrid-item {
	padding: 10px !important;
}

// .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
//   border: none !important;
// }

// // .css-ofrcno-MuiGrid-root>.MuiGrid-item{
// //   padding-left: 0px !important;
// // }

.box_chat {
	border: none !important;
}

#r1 {
	border: none !important;
}

// .MuiOutlinedInput-notchedOutline {
//   border: none !important;
// }

// fieldset{
//   border: none !important;
// }

.main_chat_grid {
	border-color: rgba(0, 0, 0, 0.23);
// align-items: center;
	padding: 0 8px;
	// pointer-events: none;
	border-radius: 4px;
	border-style: solid;
	border-width: 1px;
	width: 100%;
	bottom: 0;
	right: 0;
	// top: -8px;
	left: 0;
	position: relative;
	// height: 3em;
  margin-top: 20px !important;
}

.css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
  padding: 0 !important;

}
