/** @format */

.dashboard_title {
  margin-bottom: 0px !important;
}

.left_graph {
  width: 70%;
  margin-top: 10px;
  padding: 1.5rem;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  margin-right: 10px;
  background-color: white;

}

.right_graph {
  width: 30%;
  margin-top: 10px;
  padding:1.5rem;
  border: 1px solid #e7e7e7;
  border-radius: 10px;
  text-align: center;
  background-color: white;
  /* margin-bottom: 20px; */
}
