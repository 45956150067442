.page-title {
  /* font-family: open sans; */
  margin: 0;
  color: #1a1a1a;
  font-size: 25px;
  text-transform: uppercase;
  font-weight: 600;
}

.page-sub-title {
  color: #888;
  font-size: 15px;
  text-transform: none;
  font-weight: 400;
}

.saving_buttons {
  text-align: right;
  width: 100%;
}

.ant-col-4 {
  max-width: none !important;
}

.view_booking_heading {
width: 500px !important;
margin-right: 17px;
}
