.main_tc_container{
    height: 100vh;
    
}

.upper_tc_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  
} 

.upper_tc_container >h1{
    color: rgb(119, 134, 134);
}

.upper_tc_container > p{
    font-size: large;
    color: rgb(36, 41, 41);
}

.lower_tc_container{
     background-color:white;
     height: 100vh;
     width: 90%;
     margin: 0 auto;
}

.lower_tc_container > button {
    position: sticky;
    
    
    

}
